import { memo, lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";

import { TransitionGroup, CSSTransition } from "react-transition-group";

import Loading1 from "../components/custom/loading1";

const Banners = lazy(() => import("../views/Home/Banners/Index"));
const AboutUs = lazy(() => import("../views/AboutUs/AboutUs/Index"));
const Management = lazy(() => import("../views/AboutUs/Management/Index"));
const ImportantLinks = lazy(() =>import("../views/Home/ImportantLinks/Index"));
const VisionMission = lazy(() => import("../views/Home/VisionMission/Index"));
const Users = lazy(() => import("../views/Users/Index"));
const News = lazy(() => import("../views/News/Index"));
const Files = lazy(() => import("../views/Files/Index"));
const Careers = lazy(() => import("../views/Careers/Index"));
const Tenders = lazy(() => import("../views/Others/Tenders/Index"));
const Events = lazy(() => import("../views/Others/Events/Index"));
const PhotoGallery = lazy(() => import("../views/Media/PhotoGallery/Index"));
const VideoGallery = lazy(() => import("../views/Media/VideoGallery/Index"));
const MediaInKabco = lazy(() => import("../views/Media/MediaInKabco/Index"));


const Settings = lazy(() => import("../views/Settings/Index"));

const DefaultRouter = memo(() => {
  return (
    <TransitionGroup>
      <CSSTransition classNames="fadein" timeout={100}>
        <Suspense fallback={<Loading1 />}>
          <Switch>
            <Route path="/banner" exact component={Banners} />
            <Route path="/about-us" exact component={AboutUs} />
            <Route path="/management" exact component={Management} />
            <Route path="/important-links" exact component={ImportantLinks} />
            <Route path="/vision-mission" exact component={VisionMission} />
            <Route path="/users" exact component={Users} />
            <Route path="/files" exact component={Files} />
            <Route path="/news" exact component={News} />
            <Route path="/careers" exact component={Careers} />
            <Route path="/tenders" exact component={Tenders} />
            <Route path="/events" exact component={Events} />
            <Route path="/photo-gallery" exact component={PhotoGallery} />
            <Route path="/video-gallery" exact component={VideoGallery} />
            <Route path="/kabco-in-media" exact component={MediaInKabco} />
            <Route exact path="/settings" component={Settings} />
          </Switch>
        </Suspense>
      </CSSTransition>
    </TransitionGroup>
  );
});

DefaultRouter.displayName = "DefaultRouter";
export default DefaultRouter;
