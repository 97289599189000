import { useContext, memo, Fragment,useState } from "react";

import { Link, useLocation } from "react-router-dom";

import {
  Accordion,
  useAccordionButton,
  AccordionContext,
  Tooltip,
  OverlayTrigger,
  Nav,
} from "react-bootstrap";

import { useSelector } from "react-redux";
import * as SettingSelector from "../../../../store/setting/selectors";

import { MdWidgets, MdOutlineCategory } from "react-icons/md";

import { BiCategoryAlt } from "react-icons/bi";
import { AiOutlineAppstoreAdd, AiOutlineSetting } from "react-icons/ai";
import RightArrow from "../../../custom/RightArrow";

function CustomToggle({ children, eventKey, onClick }) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(eventKey, (active) =>
    onClick({ state: !active, eventKey: eventKey })
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <Link
      to="#"
      aria-expanded={isCurrentEventKey ? "true" : "false"}
      className="nav-link"
      role="button"
      onClick={(e) => {
        decoratedOnClick(isCurrentEventKey);
      }}
    >
      {children}
    </Link>
  );
}

const VerticalNav = memo(() => {
  let location = useLocation();
  const mode = useSelector(SettingSelector.theme_scheme_direction);
  const [activeMenu, setActiveMenu] = useState(false);
  const [active, setActive] = useState("home");

  const userType = JSON.parse(localStorage.getItem("userType"));

  return (
    <Fragment className="">
      <Accordion as="ul" className="navbar-nav iq-main-menu">
        <Accordion.Item
          as="li"
          eventKey="home"
          bsPrefix={`nav-item `}
          onClick={() => setActive("home")}
        >
          <CustomToggle
            eventKey="home"
            onClick={(activeKey) => setActiveMenu(activeKey)}
          >
            <OverlayTrigger
              placement={mode !== "en" ? "right" : "left"}
              overlay={<Tooltip>Home</Tooltip>}
            >
              <i className="icon">
                <MdWidgets className="text-black" />
              </i>
            </OverlayTrigger>
            <span
              className="item-name text-black"
              style={{ fontWeight: "600" }}
            >
              Home
            </span>
            <i className="right-icon">
              <RightArrow />
            </i>
          </CustomToggle>
          <Accordion.Collapse eventKey="home">
            <ul className="sub-nav">
              <Nav.Item
                as="li"
                style={
                  location.pathname === `/banner` ||
                    location.pathname.includes("/banner")
                    ? { background: "#CCDAE0", borderRadius: "6px" }
                    : {}
                }
              >
                <Link className={` nav-link`} to="/banner">
                  <i className="icon">
                    <AiOutlineAppstoreAdd />
                  </i>
                  <OverlayTrigger
                    placement={mode !== "en" ? "right" : "left"}
                    overlay={<Tooltip>Banner</Tooltip>}
                  >
                    <i className="sidenav-mini-icon">
                      <AiOutlineAppstoreAdd />
                    </i>
                  </OverlayTrigger>
                  <span
                    className="item-name text-black"
                    style={{ fontWeight: "600" }}
                  >
                    Banner
                  </span>
                </Link>
              </Nav.Item>
              <Nav.Item
                as="li"
                style={
                  location.pathname === `/vision-mission` ||
                    location.pathname.includes("/vision-mission")
                    ? { background: "#CCDAE0", borderRadius: "6px" }
                    : {}
                }
              >
                <Link className={` nav-link`} to="/vision-mission">
                  <i className="icon">
                    <AiOutlineAppstoreAdd />
                  </i>
                  <OverlayTrigger
                    placement={mode !== "en" ? "right" : "left"}
                    overlay={<Tooltip>Vision & Mission</Tooltip>}
                  >
                    <i className="sidenav-mini-icon">
                      <AiOutlineAppstoreAdd />
                    </i>
                  </OverlayTrigger>
                  <span
                    className="item-name text-black"
                    style={{ fontWeight: "600" }}
                  >
                    Vision & Mission
                  </span>
                </Link>
              </Nav.Item>
              <Nav.Item
                as="li"
                style={
                  location.pathname === `/important-links` ||
                    location.pathname.includes("/important-links")
                    ? { background: "#CCDAE0", borderRadius: "6px" }
                    : {}
                }
              >
                <Link className={` nav-link`} to="/important-links">
                  <i className="icon">
                    <AiOutlineAppstoreAdd />
                  </i>
                  <OverlayTrigger
                    placement={mode !== "en" ? "right" : "left"}
                    overlay={<Tooltip>Important Links</Tooltip>}
                  >
                    <i className="sidenav-mini-icon">
                      <AiOutlineAppstoreAdd />
                    </i>
                  </OverlayTrigger>
                  <span
                    className="item-name text-black"
                    style={{ fontWeight: "600" }}
                  >
                    Important Links
                  </span>
                </Link>
              </Nav.Item>
            </ul>
          </Accordion.Collapse>
        </Accordion.Item>
        <Accordion.Item
          as="li"
          eventKey="about-us"
          bsPrefix={`nav-item `}
          onClick={() => setActive("about-us")}
        >
          <CustomToggle
            eventKey="about-us"
            onClick={(activeKey) => setActiveMenu(activeKey)}
          >
            <OverlayTrigger
              placement={mode !== "en" ? "right" : "left"}
              overlay={<Tooltip>About Us</Tooltip>}
            >
              <i className="icon">
                <MdWidgets className="text-black" />
              </i>
            </OverlayTrigger>
            <span
              className="item-name text-black"
              style={{ fontWeight: "600" }}
            >
              About Us
            </span>
            <i className="right-icon">
              <RightArrow />
            </i>
          </CustomToggle>
          <Accordion.Collapse eventKey="about-us">
            <ul className="sub-nav">
              <Nav.Item
                as="li"
                style={
                  location.pathname === `/about-us` ||
                    location.pathname.includes("/about-us")
                    ? { background: "#CCDAE0", borderRadius: "6px" }
                    : {}
                }
              >
                <Link className={` nav-link`} to="/about-us">
                  <i className="icon">
                    <AiOutlineAppstoreAdd />
                  </i>
                  <OverlayTrigger
                    placement={mode !== "en" ? "right" : "left"}
                    overlay={<Tooltip>About Us</Tooltip>}
                  >
                    <i className="sidenav-mini-icon">
                      <AiOutlineAppstoreAdd />
                    </i>
                  </OverlayTrigger>
                  <span
                    className="item-name text-black"
                    style={{ fontWeight: "600" }}
                  >
                    About Us
                  </span>
                </Link>
              </Nav.Item>
              <Nav.Item
                as="li"
                style={
                  location.pathname === `/management` ||
                    location.pathname.includes("/management")
                    ? { background: "#CCDAE0", borderRadius: "6px" }
                    : {}
                }
              >
                <Link className={` nav-link`} to="/management">
                  <i className="icon">
                    <AiOutlineAppstoreAdd />
                  </i>
                  <OverlayTrigger
                    placement={mode !== "en" ? "right" : "left"}
                    overlay={<Tooltip>Management</Tooltip>}
                  >
                    <i className="sidenav-mini-icon">
                      <AiOutlineAppstoreAdd />
                    </i>
                  </OverlayTrigger>
                  <span
                    className="item-name text-black"
                    style={{ fontWeight: "600" }}
                  >
                    Management
                  </span>
                </Link>
              </Nav.Item>
            </ul>
          </Accordion.Collapse>
        </Accordion.Item>
        <li
          style={
            location.pathname === "/news"
              ? { background: "#CCDAE0", borderRadius: "6px" }
              : {}
          }
          className={` nav-item `}
        >
          <Link
            className={`nav-link`}
            aria-current="page"
            to="/news"
          >
            <OverlayTrigger
              placement={mode === "ltr" ? "right" : "left"}
              overlay={<Tooltip>News</Tooltip>}
            >
              <i className="icon">
                <MdOutlineCategory className="text-black" />
              </i>
            </OverlayTrigger>
            <span
              className="item-name text-black"
              style={{ fontWeight: "600" }}
            >
              News
            </span>
          </Link>
        </li>
        <li
          style={
            location.pathname === "/careers"
              ? { background: "#CCDAE0", borderRadius: "6px" }
              : {}
          }
          className={` nav-item `}
        >
          <Link
            className={`nav-link`}
            aria-current="page"
            to="/careers"
          >
            <OverlayTrigger
              placement={mode === "ltr" ? "right" : "left"}
              overlay={<Tooltip>Careers</Tooltip>}
            >
              <i className="icon">
                <MdOutlineCategory className="text-black" />
              </i>
            </OverlayTrigger>
            <span
              className="item-name text-black"
              style={{ fontWeight: "600" }}
            >
              Careers
            </span>
          </Link>
        </li>
        <Accordion.Item
          as="li"
          eventKey="media"
          bsPrefix={`nav-item `}
          onClick={() => setActive("media")}
        >
          <CustomToggle
            eventKey="media"
            onClick={(activeKey) => setActiveMenu(activeKey)}
          >
            <OverlayTrigger
              placement={mode !== "en" ? "right" : "left"}
              overlay={<Tooltip>Media</Tooltip>}
            >
              <i className="icon">
                <MdWidgets className="text-black" />
              </i>
            </OverlayTrigger>
            <span
              className="item-name text-black"
              style={{ fontWeight: "600" }}
            >
              Media
            </span>
            <i className="right-icon">
              <RightArrow />
            </i>
          </CustomToggle>
          <Accordion.Collapse eventKey="media">
            <ul className="sub-nav">
              <Nav.Item
                as="li"
                style={
                  location.pathname === `/photo-gallery` ||
                    location.pathname.includes("/photo-gallery")
                    ? { background: "#CCDAE0", borderRadius: "6px" }
                    : {}
                }
              >
                <Link className={` nav-link`} to="/photo-gallery">
                  <i className="icon">
                    <AiOutlineAppstoreAdd />
                  </i>
                  <OverlayTrigger
                    placement={mode !== "en" ? "right" : "left"}
                    overlay={<Tooltip>Photo Gallery</Tooltip>}
                  >
                    <i className="sidenav-mini-icon">
                      <AiOutlineAppstoreAdd />
                    </i>
                  </OverlayTrigger>
                  <span
                    className="item-name text-black"
                    style={{ fontWeight: "600" }}
                  >
                    Photo Gallery
                  </span>
                </Link>
              </Nav.Item>
              <Nav.Item
                as="li"
                style={
                  location.pathname === `/video-gallery` ||
                    location.pathname.includes("/video-gallery")
                    ? { background: "#CCDAE0", borderRadius: "6px" }
                    : {}
                }
              >
                <Link className={` nav-link`} to="/video-gallery">
                  <i className="icon">
                    <AiOutlineAppstoreAdd />
                  </i>
                  <OverlayTrigger
                    placement={mode !== "en" ? "right" : "left"}
                    overlay={<Tooltip>Video Gallery</Tooltip>}
                  >
                    <i className="sidenav-mini-icon">
                      <AiOutlineAppstoreAdd />
                    </i>
                  </OverlayTrigger>
                  <span
                    className="item-name text-black"
                    style={{ fontWeight: "600" }}
                  >
                    Video Gallery
                  </span>
                </Link>
              </Nav.Item>
              <Nav.Item
                as="li"
                style={
                  location.pathname === `/kabco-in-media` ||
                    location.pathname.includes("/kabco-in-media")
                    ? { background: "#CCDAE0", borderRadius: "6px" }
                    : {}
                }
              >
                <Link className={` nav-link`} to="/kabco-in-media">
                  <i className="icon">
                    <AiOutlineAppstoreAdd />
                  </i>
                  <OverlayTrigger
                    placement={mode !== "en" ? "right" : "left"}
                    overlay={<Tooltip>Kabco In Media</Tooltip>}
                  >
                    <i className="sidenav-mini-icon">
                      <AiOutlineAppstoreAdd />
                    </i>
                  </OverlayTrigger>
                  <span
                    className="item-name text-black"
                    style={{ fontWeight: "600" }}
                  >
                    Kabco In Media
                  </span>
                </Link>
              </Nav.Item>
            </ul>
          </Accordion.Collapse>
        </Accordion.Item>
        <Accordion.Item
          as="li"
          eventKey="others"
          bsPrefix={`nav-item `}
          onClick={() => setActive("others")}
        >
          <CustomToggle
            eventKey="others"
            onClick={(activeKey) => setActiveMenu(activeKey)}
          >
            <OverlayTrigger
              placement={mode !== "en" ? "right" : "left"}
              overlay={<Tooltip>Others</Tooltip>}
            >
              <i className="icon">
                <MdWidgets className="text-black" />
              </i>
            </OverlayTrigger>
            <span
              className="item-name text-black"
              style={{ fontWeight: "600" }}
            >
              Others
            </span>
            <i className="right-icon">
              <RightArrow />
            </i>
          </CustomToggle>
          <Accordion.Collapse eventKey="others">
            <ul className="sub-nav">
              <Nav.Item
                as="li"
                style={
                  location.pathname === `/tenders` ||
                    location.pathname.includes("/tenders")
                    ? { background: "#CCDAE0", borderRadius: "6px" }
                    : {}
                }
              >
                <Link className={` nav-link`} to="/tenders">
                  <i className="icon">
                    <AiOutlineAppstoreAdd />
                  </i>
                  <OverlayTrigger
                    placement={mode !== "en" ? "right" : "left"}
                    overlay={<Tooltip>Tenders</Tooltip>}
                  >
                    <i className="sidenav-mini-icon">
                      <AiOutlineAppstoreAdd />
                    </i>
                  </OverlayTrigger>
                  <span
                    className="item-name text-black"
                    style={{ fontWeight: "600" }}
                  >
                    Tenders
                  </span>
                </Link>
              </Nav.Item>
              <Nav.Item
                as="li"
                style={
                  location.pathname === `/events` ||
                    location.pathname.includes("/events")
                    ? { background: "#CCDAE0", borderRadius: "6px" }
                    : {}
                }
              >
                <Link className={` nav-link`} to="/events">
                  <i className="icon">
                    <AiOutlineAppstoreAdd />
                  </i>
                  <OverlayTrigger
                    placement={mode !== "en" ? "right" : "left"}
                    overlay={<Tooltip>Events</Tooltip>}
                  >
                    <i className="sidenav-mini-icon">
                      <AiOutlineAppstoreAdd />
                    </i>
                  </OverlayTrigger>
                  <span
                    className="item-name text-black"
                    style={{ fontWeight: "600" }}
                  >
                    Events
                  </span>
                </Link>
              </Nav.Item>
            </ul>
          </Accordion.Collapse>
        </Accordion.Item>
        {userType == true && <li
          style={
            location.pathname === "/users"
              ? { background: "#CCDAE0", borderRadius: "6px" }
              : {}
          }
          className={` nav-item `}
        >
          <Link
            className={`nav-link`}
            aria-current="page"
            to="/users"
          >
            <OverlayTrigger
              placement={mode === "ltr" ? "right" : "left"}
              overlay={<Tooltip>Users</Tooltip>}
            >
              <i className="icon">
                <MdOutlineCategory className="text-black" />
              </i>
            </OverlayTrigger>
            <span
              className="item-name text-black"
              style={{ fontWeight: "600" }}
            >
              Users
            </span>
          </Link>
        </li>}
        <li
          style={
            location.pathname === "/files"
              ? { background: "#CCDAE0", borderRadius: "6px" }
              : {}
          }
          className={` nav-item `}
        >
          <Link
            className={`nav-link`}
            aria-current="page"
            to="/files"
          >
            <OverlayTrigger
              placement={mode === "ltr" ? "right" : "left"}
              overlay={<Tooltip>Files</Tooltip>}
            >
              <i className="icon">
                <MdOutlineCategory className="text-black" />
              </i>
            </OverlayTrigger>
            <span
              className="item-name text-black"
              style={{ fontWeight: "600" }}
            >
              Files
            </span>
          </Link>
        </li>
        <li
          style={
            location.pathname === "/settings"
              ? { background: "#CCDAE0", borderRadius: "6px" }
              : {}
          }
          className={` nav-item `}
        >
          <Link className={`nav-link`} aria-current="page" to="/settings">
            <OverlayTrigger
              placement={mode === "ltr" ? "right" : "left"}
              overlay={<Tooltip>Settings</Tooltip>}
            >
              <i className="icon">
                <AiOutlineSetting className="text-black" />
              </i>
            </OverlayTrigger>
            <span
              className="item-name text-black"
              style={{ fontWeight: "600" }}
            >
              Settings
            </span>
          </Link>
        </li>
      </Accordion>
    </Fragment>
  );
});

VerticalNav.displayName = "VerticalNav";
export default VerticalNav;
