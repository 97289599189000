import React, { memo, lazy, Suspense } from "react";

//router
import { Switch, Redirect, Route } from "react-router";

import Loader from "../components/Loader";
//layoutpages
import Default from "../layouts/dashboard/default";
import Simple from "../layouts/dashboard/simple";
import Authmiddleware from "../router/Authmiddleware";

import ScrollTop from "../components/ScrollTop";

const userRoutes = [
  { path: "/banner", component: Default },
  { path: "/", component: () => <Redirect to="/banner" /> },

  { path: "/about-us", component: Default },
  { path: "/management", component: Default },
  { path: "/important-links", component: Default },
  { path: "/vision-mission", component: Default },
  { path: "/users", component: Default },
  { path: "/files", component: Default },
  { path: "/news", component: Default },
  { path: "/careers", component: Default },
  { path: "/events", component: Default },
  { path: "/tenders", component: Default },
  { path: "/photo-gallery", component: Default },
  { path: "/video-gallery", component: Default },
  { path: "/kabco-in-media", component: Default },
  { path: "/settings", component: Default },
  {
    path: "/errors/error404",
    component: Simple,
  },
];

const authRoutes = [
  { path: "/auth", component: Simple },
  { path: "/index", component: Simple },
  { path: "/privacy-policy", component: Simple },
];

const IndexRouters = memo(() => {
  return (
    <ScrollTop>
      <Switch>
        {authRoutes.map((route, idx) => (
          <Authmiddleware
            path={route.path}
            component={route.component}
            key={idx}
            isAuthProtected={false}
          />
        ))}
        {userRoutes.map((route, idx) => (
          <Authmiddleware
            path={route.path}
            component={route.component}
            key={idx}
            isAuthProtected={true}
            exact
          />
        ))}
      </Switch>
    </ScrollTop>
  );
});

IndexRouters.displayName = "IndexRouters";
export default IndexRouters;
